/* eslint-disable no-unused-vars */
import { takeEvery, call, put, takeLatest, all } from "redux-saga/effects";

import * as appActions from "../actions/app";
import * as actions from "../actions/orderStatus";
import * as userActions from "../actions/users";
import * as api from "../api/orderStatus";
import { dateFormatterWithTZ, dateFormatter } from "../utils";
import { TICKETMASTERURL } from "../constants";

//Open Listings
const formatOrderStatusListings = data => {
  return data.eventInfo.map(event => {
    const {
      _id,
      eventInfo: {
        eventId,
        eventName,
        eventDate,
        eventAddress,
        timeZone,
        eventUrl
      },
      section,
      row,
      quantitySold,
      eventCancel,
      status,
      eventPostponed,
      saleTime,
      invoiceId,
      currentlyTryingToBuy,
      problemBuying,
      baseCost,
      reasonProblemBuying,
      orderNum,
      problemNotes,
      readyToBuy,
      purchaseSuccessStatus,
      pdfTransferred,
      pdfAttached,
      highestRowsBack,
      unitCost,
      externalReference,
      fulfillmentCompleted,
      customerDisplayName,
      customerId,
      skyBoxEventId,
      filter
    } = event;

    return {
      listingId: _id,
      eventId,
      ticketMasterUrl: `${TICKETMASTERURL}${eventId}`,
      eventName,
      eventAddress,
      eventDate: dateFormatterWithTZ(eventDate)(timeZone),
      seat: `${section}, ${row}`,
      quantitySold,
      eventCancel,
      status,
      eventPostponed,
      saleTime: dateFormatter(saleTime),
      invoiceIdUrl: `https://skybox.vividseats.com/invoices/${invoiceId}`,
      invoiceId: invoiceId,
      currentlyTryingToBuy,
      problemBuying,
      reasonProblemBuying: reasonProblemBuying || "None",
      baseCost,
      orderNum,
      problemNotes,
      readyToBuy,
      highestRowsBack,
      unitCost,
      externalReference,
      purchaseSuccessStatus,
      pdfTransferred,
      pdfAttached,
      fulfillmentCompleted,
      customerId,
      customerDisplayName,
      skyBoxEventId,
      eventUrl: eventUrl !== undefined ? eventUrl : "",
      filter: dataFilter(status, eventCancel, eventPostponed, readyToBuy),
      eventDate1: dateFormatterWithTZ(eventDate)(timeZone),
      page: data.page,
      totalRow: data.total_rows
    };
  });
};

function dataFilter(status, eventCancel, eventPostponed, readyToBuy) {
  // if (status === "Sold") return "Sold"
  // if (eventCancel) return "Cancel"
  // if (eventPostponed) return "Postponed"
  // if (readyToBuy) return "Tracking"
  // return "Open"
  if (eventCancel) return 3;
  if (eventPostponed) return 2;
  if (readyToBuy) return 1;
  return 0;
}

const SortOrderStatus = data => {
  return data.sort(function(a, b) {
    return new Date(a.eventDate) - new Date(b.eventDate);
  });
};

function* fetchOrderStatusListingsSaga(action) {
  const {
    statusType,
    eventName,
    eventId,
    invoiceId,
    eventAddress,
    startDate,
    endDate,
    page,
    sizePerPage
  } = action.payload;
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(
      api.fetchOrderStatus,
      statusType,
      eventName,
      eventId,
      invoiceId,
      eventAddress,
      startDate,
      endDate,
      page,
      sizePerPage
    );
    yield put(
      actions.fetchOrderStatusSuccess(
        SortOrderStatus(formatOrderStatusListings(data))
      )
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* createTJOrderSaga(action) {
  try {
    console.log("createTJOrderSaga payload", action.payload);
    yield put(actions.createTJOrderBeforeRequest());
    const { data } = yield call(api.createTJOrder, action.payload);
    const { message = "Something went wrong" } = data;
    yield put(
      appActions.appReceiveAlert({
        message
      })
    );
    console.log("createTJOrderSaga request", data);
  } catch (error) {
    console.log("Error in createTJOrderSaga", error);
  } finally {
    yield put(actions.createTJOrderRequestSuccess());
  }
}

function* getCustomerDisplaySaga(action) {
  try {
    yield put(actions.getCustomerDisplayBeforeRequest());
    const { data } = yield call(api.getCustomerDisplayData, action.payload);
    yield put(actions.getCustomerDisplayRequestSuccess(data));
  } catch (error) {
    console.log("Error in getCustomerDisplaySaga", error);
    yield put(actions.getCustomerDisplayRequestSuccess([]));
  }
}

function* getAllManualOrdersSaga(action) {
  try {
    yield put(actions.manualOrdersBeforeRequest());
    const { data } = yield call(api.getAllManualOrders);
    yield put(actions.getManualOrdersSuccess(data));
  } catch (error) {
    console.log("Error in getAllManualOrdersSaga", error);
    yield put(actions.getManualOrdersSuccess([]));
  }
}

function* saveBrokerOrderRequestSaga(action) {
  try {
    yield put(actions.saveBrokerOrderBeforeRequest());
    const { data } = yield call(api.saveBrokerOrderRequest, action.payload);
    const {
      success = false,
      message = "Something went wrong while processing your request",
      payload = null
    } = data;
    if (success) {
      if (payload._id) yield put(actions.saveBrokerOrderChangeStatus(payload));
      yield put(
        appActions.appReceiveAlert({
          message
        })
      );
    }
    yield put(actions.saveBrokerOrderRequestSuccess());
  } catch (error) {
    console.log("Error in saveBrokerOrderRequestSaga", error);
    yield put(actions.saveBrokerOrderRequestSuccess());
  }
}

function* deleteManualOrderSaga(action) {
  try {
    yield put(actions.deleteManualOrderBeforeRequest());
    const { data } = yield call(api.deleteManualOrder, action.payload);
    const {
      success = false,
      message = "Something went wrong while processing your request"
    } = data;
    if (success) {
      yield put(actions.deleteManualOrderFilter(action.payload));
      yield put(
        appActions.appReceiveAlert({
          message
        })
      );
    }
    yield put(actions.deleteManualOrderSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.deleteManualOrderSuccess());
  }
}

function* watchListingSaga() {
  //Open Sale Listings
  yield takeLatest(
    actions.FETCH_ORDER_STATUS_REQUEST,
    fetchOrderStatusListingsSaga
  );

  yield takeLatest(actions.CREATE_TJ_ORDER, createTJOrderSaga);
  yield takeLatest(actions.GET_CUSTOMER_DISPLAY, getCustomerDisplaySaga);
  yield takeLatest(actions.GET_MANUAL_ORDERS, getAllManualOrdersSaga);
  yield takeLatest(
    actions.SAVE_BROKER_ORDER_REQUEST,
    saveBrokerOrderRequestSaga
  );
  yield takeLatest(actions.DELETE_MANUAL_ORDER, deleteManualOrderSaga);
}

export default watchListingSaga;
